import React from "react";
import { navigate } from "gatsby";
import { PageProps } from "gatsby";
import { Layout, Container } from "components/layout";
import { useAuth } from "auth";
import { ViewHeader } from "components/view/ViewHeader";
import { BuilderMap } from "components/view/Builder";
import { BuilderStoreProvider } from "components/view/Builder/BuilderStore/BuilderStore";
import { Location } from "store/types";

interface BuilderPageProps extends PageProps {
  location: PageProps["location"] & {
    state?: {
      mapId: string;
      locations: Location[];
    };
  };
}

export default function BuilderPage(props: BuilderPageProps) {
  const {
    location: { state },
  } = props;

  const { token } = useAuth();

  React.useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  return (
    <BuilderStoreProvider>
      <Layout noOverflow>
        <ViewHeader />
        <Container noPadding>
          <BuilderMap mapId={state?.mapId} locations={state?.locations} />
        </Container>
      </Layout>
    </BuilderStoreProvider>
  );
}
